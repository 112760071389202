import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default function StatusIcon({ status }: { status: number }) {
  if (status === 1) {
    return <CheckOutlined className="success" />;
  } else if (status === 2) {
    return <Spin className="loading" />;
  } else {
    return <CloseOutlined className="fail" />;
  }
}
