import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col, Select as AntSelect } from "antd";
import { Form, Cascader, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import {
  Report as ReportType,
  ReportColumn as ReportColumnT,
  ReportColumnType,
} from "/app/src/models";
import { reportColumnService } from "/app/src/services";
import FormikDisabler from "/app/src/components/generic/components/formikDisabler";
import DisabledSubmitButton from "/app/src/components/generic/components/buttons/DisabledSubmitButton";
import { useAccessContext } from "/app/src/contexts/hooks/useAccessContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { cascadeReportColumnTypes } from "/app/src/helpers/cascadeReportColumnTypes";
import Comparison from "./comparison";

interface FormValues {
  tableColumn: [string, number] | undefined;
  name: string | undefined;
  reportId?: number | undefined;
  firstColumn: number | undefined;
  secondColumn: number | undefined;
}

function filter(inputValue: string, path: any[]) {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
  );
}

export default function NewReportColumn({
  report,
  reportColumnTypes,
  columns,
}: {
  report: ReportType;
  reportColumnTypes: ReportColumnType[];
  columns: ReportColumnT[];
}) {
  const { t } = useTranslation();

  const [namePlaceholder, setNamePlaceholder] = useState("");
  const { canEdit } = useAccessContext();
  const queryClient = useQueryClient();
  const [newColumnType, setNewColumnType] = useState<"column" | "comparison">(
    "column",
  );

  const formatForm = useCallback(
    (values: FormValues, placeholder: string): ReportColumnT => {
      if (!values.name) {
        //if no name, use the placeholder value as the name
        values.name = placeholder;
      }
      const ret: ReportColumnT = {
        name: values.name,
        reportId: values.reportId,
        reportColumnTypeId: undefined,
        type: newColumnType,
      };
      if (newColumnType === "column" && values?.tableColumn) {
        ret.reportColumnTypeId = values.tableColumn[1];
      }
      if (newColumnType === "comparison") {
        if (values.firstColumn) {
          if (values.firstColumn !== 0) {
            ret.firstColumn = values.firstColumn;
          }
        }
        if (values.secondColumn) {
          if (values.secondColumn !== 0) {
            ret.secondColumn = values.secondColumn;
          }
        }
      }
      return ret;
    },
    [newColumnType],
  );

  const { mutateAsync: addColumn } = useMutation({
    mutationFn: (values: ReportColumnT) => {
      return reportColumnService.createSingle(values).then(handlePromiseError);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["reportColumns", report.id],
        (oldData: { report_columns: ReportColumnT[] }) => {
          return {
            report_columns: [...oldData.report_columns, data.report_column],
          };
        },
      );
    },
  });

  const addColumnHandler = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      values.reportId = report.id;
      const formattedValues = formatForm(values, namePlaceholder);
      await addColumn(formattedValues).then(() => {
        actions.resetForm();
        //reset the placeholder name to default value
        setNamePlaceholder(t("translation:enter_name"));
      });
    },
    [report.id, addColumn, formatForm, namePlaceholder, t],
  );

  useEffect(() => {
    setNamePlaceholder(t("translation:enter_name"));
  }, [t]);
  const handleTypeChange = useCallback((value: "column" | "comparison") => {
    setNewColumnType(value);
  }, []);
  const adjustPlaceholder = useMemo(
    () => (e: [string, number]) => {
      //set the report column's placeholder name
      if (e[1] === 0) {
        setNamePlaceholder(t("translation:select_column"));
      }
      const col = reportColumnTypes.filter((c) => c.id === e[1])[0];
      //include the table name if the baseTable doesn't match table
      if (col.baseTable !== col.finalTable) {
        setNamePlaceholder(`${col.finalTable} ${col.name}`);
      } else {
        if (col.name) {
          setNamePlaceholder(col.name);
        }
      }
    },
    [reportColumnTypes, t],
  );

  const newColumnForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid, isSubmitting }) => (
        <Form>
          <FormikDisabler disabled={!canEdit} />
          <Row justify="start" gutter={16}>
            <Col span={3}>
              <AntSelect
                onChange={handleTypeChange}
                value={newColumnType}
                style={{ width: "100%" }}
                size="large"
              >
                <AntSelect.Option value="column">
                  {t("translation:column")}
                </AntSelect.Option>
                <AntSelect.Option value="comparison">
                  {t("translation:comparison")}
                </AntSelect.Option>
              </AntSelect>
            </Col>
            {newColumnType === "column" ? (
              <Col span={10}>
                <Form.Item name="tableColumn">
                  <Cascader
                    name="tableColumn"
                    size="large"
                    className="larger-cascade"
                    options={cascadeReportColumnTypes(
                      reportColumnTypes,
                      report.baseTable,
                    )}
                    placeholder={t("translation:select_column")}
                    showSearch={{ filter }}
                    onChange={adjustPlaceholder}
                  />
                </Form.Item>
              </Col>
            ) : (
              <Comparison columns={columns} />
            )}

            <Col span={6}>
              <Form.Item name="name" hasFeedback={false}>
                <Input name="name" placeholder={namePlaceholder} size="large" />
              </Form.Item>
            </Col>
            <Col span={5}>
              <DisabledSubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || isSubmitting}
              >
                {t("translation:add_column")}
              </DisabledSubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [
        canEdit,
        handleTypeChange,
        newColumnType,
        t,
        reportColumnTypes,
        report.baseTable,
        adjustPlaceholder,
        columns,
        namePlaceholder,
      ],
    );
  return (
    <Formik
      component={newColumnForm}
      initialValues={{
        tableColumn: undefined,
        name: "",
        firstColumn: undefined,
        secondColumn: undefined,
      }}
      onSubmit={addColumnHandler}
    />
  );
}
