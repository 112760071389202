import React from "react";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

export default function ToolTip({
  tip = "",
  icon = "question",
  fontSize = "",
}: {
  tip: string;
  icon?: "question" | "info" | "warning";
  fontSize?: string;
}) {
  if (icon === "question") {
    return (
      <Tooltip title={tip}>
        <QuestionCircleOutlined style={{ paddingLeft: "5px", fontSize }} />
      </Tooltip>
    );
  } else if (icon === "info") {
    return (
      <Tooltip title={tip}>
        <InfoCircleOutlined style={{ paddingLeft: "5px", fontSize }} />
      </Tooltip>
    );
  } else if (icon === "warning") {
    return (
      <Tooltip title={tip}>
        <ExclamationCircleOutlined
          style={{ paddingLeft: "5px", color: "orange", fontSize }}
        />
      </Tooltip>
    );
  }
}
