import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRollbar } from "@rollbar/react";
import {
  statusService,
  userService,
  connectionService,
  rolePermissionService,
  settingService,
} from "./services";
import { ConnectionState, StatusType } from "./contexts/types";
import { parseConnections } from "./helpers/connectionsStatus";
import { useAuthState } from "/app/src/contexts/authentication";
import { buildParams } from "./helpers";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { permissionsToDict } from "./helpers/permissions";
import { TimeZoneState } from "./contexts/types";

/**
 * Custom hook for the AppBody component
 * @returns statusQuery, powerpickConnectionQuery, timeZone, permissionsQuery
 */
export default function AppBodyHook() {
  const { user, authType, isAuthenticated } = useAuthState();
  const [versionChanged, setVersionChanged] = useState(false);
  const rollbar = useRollbar();
  const [timeZone, setTimeZone] = useState<TimeZoneState>({
    timeZone: null,
    userTimeZone: null,
    globalTimeZone: null,
    powerPickTimeZone: null,
  });
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();

  const statusQuery = useQuery({
    queryKey: ["status"],
    queryFn: () => {
      return statusService
        .getStatus()
        .then((response) => {
          return {
            loading: false,
            connected: true,
            ...response,
          };
        })
        .catch((error) => {
          //set connected to false if the connection is lost
          queryClient.setQueryData(["status"], (oldData: object) => {
            return { ...oldData, connected: false, loading: false };
          });
          throw error;
        });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["status"], (oldData: StatusType) => {
        if (oldData.version !== response.version) {
          setVersionChanged(true);
        } else {
          setVersionChanged(false);
        }
        return { ...oldData, ...response };
      });
    },
    refetchInterval: (data) => {
      //disable refetching if the connection is lost
      if (data.connected === false) {
        return false;
      }
      return 15000;
    },
    retryDelay: 10000,
    retry: 3,
    initialData: {
      loading: true,
      connected: true,
      message: "loading",
      timeZone: "utc",
      licensedTo: "",
    } as StatusType,
  });

  const { data: license } = useQuery({
    queryKey: ["license"],
    queryFn: () => {
      return statusService.getLicense();
    },
    initialData: {
      powerPickVersion: "",
      license: "",
    },
    select: (data) => {
      return data;
    },
    enabled: isAuthenticated,
  });
  //useQuery for getting the powerpick timezone
  const { data: powerpickTimeZone } = useQuery({
    queryKey: ["powerpickTimeZone"],
    queryFn: () => {
      return settingService
        .getAll(buildParams({ number: 107 }))
        .then((response) => {
          return response.settings[0];
        });
    },
    initialData: { value: null },
    select: (data) => {
      if (data.value === null) {
        return { value: "utc" };
      }
      return data;
    },
    enabled: isAuthenticated,
  });
  //Set Time zone
  useEffect(() => {
    const timeZone = {
      userTimeZone: user?.timeZone,
      globalTimeZone: statusQuery.data.timeZone,
      timeZone: user?.timeZone || statusQuery.data.timeZone,
      powerPickTimeZone: powerpickTimeZone.value,
    };
    setTimeZone(timeZone);
  }, [user, statusQuery.data.timeZone, powerpickTimeZone.value]);

  useEffect(() => {
    //only set language if user is loaded correctly
    if (user?.language) {
      i18n.changeLanguage(user.language);
    } else {
      i18n.changeLanguage("en");
    }
  }, [user, i18n]);

  const powerpickConnectionQuery = useQuery({
    queryKey: ["powerpickConnection"],
    queryFn: () => {
      return connectionService
        .getAll(
          buildParams({ type: "[or]PowerPick SQL;PowerPick Web Services" }),
        )
        .then((response) => {
          return parseConnections(response.connections);
        });
    },
    retryDelay: 25000, //25 seconds when API connection is lost
    refetchInterval: 15000, //15 seconds
    enabled: isAuthenticated,
    initialData: { powerPick: 1, webServices: 1 } as ConnectionState,
  });

  const navigate = useNavigate();
  const { data: userCount } = useQuery({
    queryKey: ["userCount"],
    queryFn: () => userService.getCount(),
    staleTime: 60000, //1 minute
    enabled: authType !== "azure" || isAuthenticated,
    select: (data) => {
      return data.count;
    },
  });

  // Set Rollbar data
  useEffect(() => {
    if (user) {
      rollbar.configure({
        enabled: true, //enable once a user is logged in
        payload: {
          person: {
            id: `${statusQuery.data.licensedTo}-${user.id}`,
            username: user.username,
          },
        },
      });
    } else {
      rollbar.configure({
        enabled: false,
      });
    }
  }, [user, rollbar, statusQuery.data]);

  useEffect(() => {
    if (userCount !== undefined && userCount === 0) {
      navigate("/signup");
    }
  }, [userCount, navigate]);

  useEffect(() => {
    if (!statusQuery.data?.loading && statusQuery.data?.message !== "loading") {
      if (statusQuery.data?.message === "locked") {
        navigate("/locked");
      }
    }
  }, [navigate, statusQuery.data?.message, statusQuery.data?.loading]);

  const permissionsQuery = useQuery({
    queryKey: ["permissions", user?.roleId],
    queryFn: () => {
      return rolePermissionService.getRolePermissions(user?.roleId as number);
    },
    enabled: isAuthenticated,
    select: (data) => {
      return permissionsToDict(data.permissions);
    },
    initialData: {
      permissions: [],
    },
  });

  return {
    statusQuery,
    powerpickConnectionQuery,
    timeZone,
    permissionsQuery,
    license,
    versionChanged,
  };
}
