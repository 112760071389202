import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { Integration } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ToolTip from "/app/src/components/generic/components/toolTip";
import useSetting from "/app/src/components/settings/setting";

interface FormValues {
  value: string | undefined;
}

/**
 * Component to display the Date time format setting for a single Data Pull Integration
 */
export default function DateTimeFormat({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { settingValue, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    type: "dateTimeFormat",
  });

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createUpdateSetting({
        ...values,
        type: "dateTimeFormat",
        integrationId: integration.id,
      }).then(() => {
        actions.resetForm();
      });
    },
    [createUpdateSetting, integration.id],
  );
  const labelContent = useMemo(
    () => (
      <>
        {t("translation:data_pull_time_format")}
        <ToolTip tip={t("translation:data_pull_time_format_description")} />
      </>
    ),
    [t],
  );

  const form: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ isSubmitting, dirty }) => (
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="value" label={labelContent}>
              <Select name="value" size="large">
                {[
                  { name: "YYYYMMDD.0", value: "%Y%m%d.0" },
                  { name: "MM/DD/YYYY", value: "%m/%d/%Y" },
                  { name: "DD/MM/YYYY", value: "%d/%m/%Y" },
                  { name: "YYYY-MM-DD", value: "%Y-%m-%d" },
                  { name: "YYYY-MM-DD HH:MM:SS", value: "%Y-%m-%d %H:%M:%S" },
                  {
                    name: "YYYY-MM-DDTHH:MM:SS.SSS",
                    value: "%Y-%m-%d%H:%M:%S.%f",
                  },
                  {
                    name: "YYYY-MM-DDTHH:MM:SSZ",
                    value: "%Y-%m-%dT%H:%M:%SZ",
                  },
                  {
                    name: "YYYY-MM-DD HH:MM:SS.SSSZ",
                    value: "%Y-%m-%d %H:%M:%S.%fZ",
                  },
                ].map((timeFormat) => (
                  <Select.Option
                    key={timeFormat.value}
                    value={timeFormat.value}
                  >
                    {timeFormat.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [labelContent, t],
  );
  return (
    <Formik
      initialValues={settingValue ? { value: settingValue } : { value: "" }}
      validationSchema={simpleSchemaBuilder([
        { name: "value", type: "string", required: true },
      ])}
      onSubmit={onSubmit}
      component={form}
      enableReinitialize
    />
  );
}
