import React, { useCallback, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Row, Col, Button } from "antd";
import { RightSquareOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, Input, SubmitButton } from "formik-antd";

import NewMapping, { formatForm } from "./newMapping";
import { mappingService } from "/app/src/services";
import {
  ColumnType,
  InternalIdType,
  TextType,
  AdvancedType,
} from "./mappingTypes";
import { useTranslation } from "react-i18next";
import {
  Integration,
  Mapping,
  MappingType,
  ReportColumnType,
} from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

interface FormValues {
  key: string | string[] | undefined;
  value: string | undefined;
  columnTypeId: number | [string | undefined, number | undefined] | undefined;
  type: MappingType | undefined;
  parentMappingId: number | undefined;
}

/**
 * Get the label for the mapping type
 */
function getMappingLabel(mapping: Mapping, integration: Integration) {
  if (integration.baseTable === "Material" && mapping.type === "data") {
    return "materials";
  }
  return mapping.type;
}

/**
 * Component to edit the mapping
 */
export default function EditMapping({
  isThemed,
  integration,
  columnTypes,
  mapping,
  updateMapping,
  removeMapping,
}: {
  isThemed: boolean;
  integration: Integration;
  columnTypes: ReportColumnType[];
  mapping: Mapping;
  updateMapping: (mapping: Mapping) => void;
  removeMapping: (mapping: Mapping) => void;
}) {
  const updateMappingHandler = useCallback(
    async (values: FormValues) => {
      if (mapping?.id) {
        const formattedValues = formatForm(values);
        await updateMapping({ id: mapping.id, ...formattedValues });
      }
    },
    [mapping.id, updateMapping],
  );

  /**
   * Form for a column type mapping
   */
  const columnTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <ColumnType
            isThemed={isThemed}
            columnTypes={columnTypes}
            removeMapping={removeMapping}
            mapping={mapping}
            dirty={dirty}
            isValid={isValid}
          />
        </Form>
      ),
      [columnTypes, isThemed, mapping, removeMapping],
    );
  /**
   * Form for a text type mapping
   */
  const textTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <TextType
            removeMapping={removeMapping}
            mapping={mapping}
            dirty={dirty}
            isValid={isValid}
          />
        </Form>
      ),
      [mapping, removeMapping],
    );

  /**
   * For for an internal id mapping
   */
  const idTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <InternalIdType
            removeMapping={removeMapping}
            mapping={mapping}
            dirty={dirty}
            isValid={isValid}
          />
        </Form>
      ),
      [mapping, removeMapping],
    );

  const advancedTypeForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <AdvancedType
            removeMapping={removeMapping}
            mapping={mapping}
            dirty={dirty}
            isValid={isValid}
            isThemed={isThemed}
            columnTypes={columnTypes}
          />
        </Form>
      ),
      [columnTypes, isThemed, mapping, removeMapping],
    );

  /**
   * Get the initial values for the form
   */
  const getInitialValues = () => {
    let columnType:
      | number
      | [string | undefined, number | undefined]
      | undefined = mapping.columnTypeId;
    if (isThemed) {
      columnType = [mapping.value, mapping.columnTypeId];
    }
    return {
      key: mapping.key,
      value: mapping.value,
      columnTypeId: columnType,
      parentMappingId: mapping.parentMappingId,
      type: mapping.type,
    } as {
      key: string | string[] | undefined;
      value: string | undefined;
      columnTypeId:
        | number
        | [string | undefined, number | undefined]
        | undefined;
      parentMappingId: number | undefined;
      type: MappingType | undefined;
    };
  };

  /**
   * Converts advanced type initial values to the correct format - list of strings
   */
  const getAdvancedInitialValues = () => {
    const initValues = getInitialValues();
    if (initValues.type === "advanced") {
      if (typeof initValues.key === "string") {
        initValues.key = initValues.key?.split(",");
      }
    }
    return initValues;
  };
  return (
    <Col span={24}>
      {["lines", "grouping", "data"].includes(mapping.type) && (
        <EditGroupingType
          integration={integration}
          mapping={mapping}
          updateMapping={updateMapping}
          removeMapping={removeMapping}
          columnTypes={columnTypes}
        />
      )}
      {mapping.type === "column" && (
        <Formik
          enableReinitialize
          component={columnTypeForm}
          initialValues={getInitialValues()}
          validationSchema={simpleSchemaBuilder([
            { name: "key", type: "string", required: true },
          ])}
          onSubmit={updateMappingHandler}
        />
      )}
      {mapping.type === "text" && (
        <Formik
          enableReinitialize
          component={textTypeForm}
          initialValues={getInitialValues()}
          validationSchema={simpleSchemaBuilder([
            { name: "key", type: "string", required: true },
            { name: "value", type: "string", required: true },
          ])}
          onSubmit={updateMappingHandler}
        />
      )}
      {mapping.type === "id" && (
        <Formik
          enableReinitialize
          component={idTypeForm}
          initialValues={getInitialValues()}
          validationSchema={simpleSchemaBuilder([
            { name: "key", type: "string", required: true },
          ])}
          onSubmit={updateMappingHandler}
        />
      )}
      {mapping.type === "advanced" && (
        <Formik
          enableReinitialize
          component={advancedTypeForm}
          initialValues={getAdvancedInitialValues()}
          validationSchema={simpleSchemaBuilder([
            { name: "key", type: "mixed", required: true },
          ])}
          onSubmit={updateMappingHandler}
        />
      )}
    </Col>
  );
}
/**
 * Component to edit the grouping type mapping
 */
function EditGroupingType({
  integration,
  mapping,
  updateMapping,
  removeMapping,
  columnTypes,
}: {
  integration?: Integration;
  mapping: Mapping;
  updateMapping: (mapping: Mapping) => void;
  removeMapping: (mapping: Mapping) => void;
  columnTypes: ReportColumnType[];
}) {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const queryClient = useQueryClient();

  const { data: children } = useQuery({
    queryKey: ["mappings", { parentMappingId: mapping.id }],
    queryFn: () => {
      return mappingService.getAll(
        buildParams({ parentMappingId: mapping.id }),
      );
    },
    initialData: { mappings: [] },
    select: (data: { mappings: Mapping[] }) => {
      return data.mappings;
    },
  });

  const handleCircleClick = useCallback(() => {
    setEditing(true);
  }, []);

  const { mutateAsync: addMappingChild } = useMutation({
    mutationFn: (newMapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService.createSingle(newMapping).then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { parentMappingId: mapping.id }],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: [...oldData.mappings, response.mapping],
          };
        },
      );
    },
  });

  const { mutateAsync: removeMappingChild } = useMutation({
    mutationFn: (mapping: Mapping) => {
      return mappingService.deleteSingle(mapping.id).then(() => {
        const mappingId = mapping.id;
        return { mappingId };
      });
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { parentMappingId: mapping.id }],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.filter(
              (mapping) => mapping.id !== response.mappingId,
            ),
          };
        },
      );
    },
  });

  const { mutateAsync: updateMappingChild } = useMutation({
    mutationFn: (mapping: Omit<Mapping, "parentMapping" | "children">) => {
      return mappingService
        .updateSingle(mapping.id, mapping)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["mappings", { parentMappingId: mapping.id }],
        (oldData: { mappings: Mapping[] }) => {
          return {
            mappings: oldData.mappings.map((mapping) => {
              if (mapping.id === response.mapping.id) {
                return response.mapping;
              }
              return mapping;
            }),
          };
        },
      );
    },
  });

  /**
   * Form submission handler for updating the mapping
   */
  const onSubmitUpdateMapping = useCallback(
    async (values: Omit<Mapping, "parentMapping" | "children">) => {
      if (mapping?.id) {
        await updateMapping({ id: mapping.id, ...values });
      }
    },
    [mapping, updateMapping],
  );
  /**
   * Delete Mapping button handlers
   */
  const handleDelete = useCallback(() => {
    removeMapping(mapping);
  }, [mapping, removeMapping]);

  const mappingTitle = t(
    `translation:${getMappingLabel(mapping, integration)}_mapping`,
  );

  /**
   * Form for a grouping type mapping
   */
  const editGroupingForm: (
    props: FormikProps<{ key: string | undefined }>,
  ) => JSX.Element = useCallback(
    ({ dirty }) => (
      <Form>
        <Row justify="start" gutter={16}>
          <Col span={2}>{mappingTitle}:</Col>
          <Col span={16}>
            <Form.Item name="key" hasFeedback={false}>
              <Input
                size="large"
                name="key"
                placeholder={t("translation:enter_key")}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton type="primary" size="large" block disabled={!dirty}>
              {t("translation:save")}
            </SubmitButton>
          </Col>
          <Col span={3}>
            <Button onClick={handleDelete} type="default" size="large" block>
              {t("translation:remove")}
            </Button>
          </Col>
        </Row>
      </Form>
    ),
    [handleDelete, mappingTitle, t],
  );

  return (
    <>
      <Formik
        enableReinitialize
        component={editGroupingForm}
        initialValues={{ key: mapping.key }}
        validationSchema={simpleSchemaBuilder([
          { name: "key", type: "string", required: true },
        ])}
        onSubmit={onSubmitUpdateMapping}
      />
      <Row justify="start" gutter={16}>
        <Col span={24}>
          {children?.map((child) => (
            <div key={child.id}>
              <Row justify="start" gutter={16}>
                <Col offset={3} span={1}>
                  <RightSquareOutlined
                    style={{ fontSize: "28px", color: "#82878e" }}
                  />
                </Col>
                <Col span={20}>
                  <EditMapping
                    integration={integration}
                    isThemed={integration.baseTable !== null}
                    columnTypes={columnTypes}
                    mapping={child}
                    removeMapping={removeMappingChild}
                    updateMapping={updateMappingChild}
                  />
                </Col>
              </Row>
            </div>
          ))}
        </Col>
      </Row>
      <Row justify="start" gutter={16}>
        <Col offset={3} span={1}>
          <RightSquareOutlined style={{ fontSize: "28px", color: "#82878e" }} />
        </Col>
        <Col span={20}>
          {editing ? (
            <NewMapping
              integration={integration}
              addMapping={addMappingChild}
              columnTypes={columnTypes}
              parentId={mapping.id}
            />
          ) : (
            <PlusCircleOutlined
              style={{ fontSize: "28px", color: "#1890ff" }}
              onClick={handleCircleClick}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
