import { Formik, FormikProps } from "formik";
import { Form, Input, SubmitButton } from "formik-antd";
import { Col, Divider } from "antd";
import "./_updateLicense.scss";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { settingService } from "/app/src/services";
import { settingsSchema } from "/app/src/schemas/settingsSchema";
import { useNavigate } from "react-router-dom";
import { StatusType } from "/app/src/contexts/types";

/**
 *
 * @returns Update License form
 */
export default function UpdateLicenseForm() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  //useMutation for updating the license
  const { mutateAsync: updateLicense } = useMutation({
    mutationFn: (values: { license: string }) => {
      return settingService
        .updateSettings({ license: values.license })
        .then((response) => {
          if (response?.errors) {
            return Promise.reject(response);
          }
          response.license = values.license;
          return Promise.resolve(response);
        });
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["license"], { license: data.license });
    },
  });

  const onSubmitLicense = useCallback(
    async (
      {
        license,
      }: {
        license: string;
      },
      {
        setFieldError,
      }: { setFieldError: (field: string, value: string) => void },
    ): Promise<void> => {
      await updateLicense({ license })
        .then(() => {
          queryClient.setQueryData(["status"], (oldData: StatusType) => {
            return {
              ...oldData,
              message: "active",
            };
          });
          navigate("/dashboard ");
        })
        .catch((error) => {
          return setFieldError("license", error.errors[0]);
        });
    },
    [navigate, queryClient, updateLicense],
  );

  const updateLicenseForm: (
    props: FormikProps<{ license: string }>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Col span={24}>
          <Form.Item name="license">
            <Input.TextArea
              name="license"
              size="large"
              placeholder={t("translation:license")}
            />
          </Form.Item>
        </Col>
        <Divider />

        <Col span={5} offset={19}>
          <SubmitButton
            type="primary"
            size="large"
            className="updateLicenseButton"
            block
            disabled={!dirty || isSubmitting}
          >
            {t("translation:save")} {t("translation:license")}{" "}
            <ArrowRightOutlined />
          </SubmitButton>
        </Col>
      </Form>
    ),
    [t],
  );
  return (
    <Formik
      component={updateLicenseForm}
      initialValues={{ license: "" }}
      validationSchema={settingsSchema}
      enableReinitialize
      onSubmit={onSubmitLicense}
    />
  );
}
