import { useMemo, useCallback } from "react";
import { useFormikContext } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import ToolTip from "/app/src/components/generic/components/toolTip";
import DynamicButtons from "/app/src/components/generic/components/apps/data/settings/connectionSettings/dynamicButtons";

interface FormValues {
  value: string | undefined;
  name: string | undefined;
}

/**
 *
 * @returns RequestForm
 */
export default function RequestForm() {
  const { isSubmitting, dirty, setFieldValue, values } =
    useFormikContext<FormValues>();
  const { t } = useTranslation();

  const methodLabelContent = useMemo(
    () => (
      <>
        {t("translation:method")}
        <ToolTip tip={t("translation:method_tip")} />
      </>
    ),
    [t],
  );
  const requestBodyContent = useMemo(
    () => (
      <>
        {t("translation:request_body")}
        <ToolTip tip={t("translation:request_body_tip")} />
      </>
    ),
    [t],
  );

  const requestCheck = useCallback(
    (e) => {
      if (e === "GET") {
        setFieldValue("value", "");
      }
    },
    [setFieldValue],
  );

  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item name="name" label={methodLabelContent} hasFeedback={false}>
            <Select name="name" size="large" onChange={requestCheck}>
              <Select.Option value="GET">{t("translation:get")}</Select.Option>
              <Select.Option value="POST">
                {t("translation:post")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name="value"
            label={requestBodyContent}
            hasFeedback={false}
          >
            <Input.TextArea
              name="value"
              size="large"
              disabled={values.name === "GET"}
            />
            <DynamicButtons disabled={values.name === "GET"} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <SubmitButton
            type="primary"
            size="large"
            block
            disabled={!dirty || isSubmitting}
            style={{ marginTop: "30px" }}
          >
            {t("translation:save")}
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
}
