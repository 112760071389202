export function isAPIType(connectionType: string | undefined) {
  const apiTypes = ["No Auth", "Basic", "OAuth1"];
  if (connectionType && apiTypes.includes(connectionType)) {
    return true;
  }
  return false;
}

/**
 * Check if the connection type is SQL-based
 */
export function isSQLType(connectionType: string | undefined) {
  const sqlTypes = ["MSSQL", "IBM2"];
  return connectionType && sqlTypes.includes(connectionType);
}
