import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Checkbox } from "formik-antd";
import { Integration } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ToolTip from "/app/src/components/generic/components/toolTip";
import useSetting from "/app/src/components/settings/setting";

interface FormValues {
  value: boolean | undefined;
}

/**
 * Component to display the default direction type setting for a single Data Pull Integration
 */
export default function AutoDetectDirection({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { settingValue, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    type: "autoDetectDirection",
  });

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createUpdateSetting({
        ...values,
        type: "autoDetectDirection",
        integrationId: integration.id,
      }).then(() => {
        actions.resetForm();
      });
    },
    [createUpdateSetting, integration.id],
  );
  const labelContent = useMemo(
    () => (
      <>
        {t("translation:auto_detect_direction_type")}
        <ToolTip tip={t("translation:auto_detect_direction_type_tip")} />
      </>
    ),
    [t],
  );
  const form: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ isSubmitting, dirty }) => (
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="value" label={labelContent}>
              <Checkbox name="value" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [labelContent, t],
  );
  return (
    <Formik
      initialValues={
        settingValue && settingValue !== "0"
          ? { value: true }
          : { value: false }
      }
      validationSchema={simpleSchemaBuilder([
        { name: "value", type: "string", required: true },
      ])}
      onSubmit={onSubmit}
      component={form}
      enableReinitialize
    />
  );
}
