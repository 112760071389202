import { Row, Col } from "antd";
import { Form, Input, SubmitButton, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { Mapping } from "/app/src/models";
import { Input as AntdInput } from "antd";
import { Formik, useFormikContext } from "formik";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { useCallback, useEffect, useState } from "react";

interface FormValues {
  key: string;
  value: string;
}

/**
 * Fields for the grouping confirmation type
 */
function GroupingType() {
  const { t } = useTranslation();
  return (
    <>
      <Col span={2}>{t("translation:grouping_field")}:</Col>
      <Col span={5}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={10} />
    </>
  );
}

/**
 * Fields for the mapping confirmation type
 */
function MappingTypes({ mappings }: { mappings: Mapping[] }) {
  const { t } = useTranslation();

  return (
    <>
      <Col span={2}>{t("translation:mapping_field")}:</Col>
      <Col span={5}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={10}>
        <Form.Item name="value" hasFeedback={false}>
          <Select name="value" size="large">
            {mappings.map((mapping) => (
              <Select.Option
                key={mapping.id}
                value={String(mapping.id)}
                label={mapping.key}
              >
                {mapping.key}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}

/**
 * Fields for the status confirmation type
 */
function StatusFieldType({ title }: { title: string }) {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<FormValues>();
  const [successValue, setSuccessValue] = useState<string>("");
  const [failureValue, setFailureValue] = useState<string>("");

  useEffect(() => {
    if (values.value) {
      const [success, failure] = values.value.split(",");
      setSuccessValue(success);
      setFailureValue(failure);
    }
  }, [values.value]);

  const handleSuccessChange = useCallback(
    (e) => {
      setFieldValue("value", `${e.target.value},${failureValue}`);
    },
    [setFieldValue, failureValue],
  );

  const handleFailureChange = useCallback(
    (e) => {
      setFieldValue("value", `${successValue},${e.target.value}`);
    },
    [setFieldValue, successValue],
  );

  return (
    <>
      <Col span={2}>{title}:</Col>
      <Col span={5}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={5}>
        <AntdInput
          addonBefore="Success:"
          size="large"
          placeholder={t("translation:success")}
          onChange={handleSuccessChange}
          value={successValue}
        />
      </Col>
      <Col span={5}>
        <AntdInput
          size="large"
          addonBefore="Error:"
          placeholder={t("translation:error")}
          onChange={handleFailureChange}
          value={failureValue}
        />
      </Col>
    </>
  );
}

/**
 * Fields for the text confirmation type
 */
function TextFieldType({
  title,
  valueDisabled = false,
  placeholder,
}: {
  title: string;
  valueDisabled?: boolean;
  placeholder?: string;
}) {
  const { t } = useTranslation();
  return (
    <>
      <Col span={2}>{title}:</Col>
      <Col span={5}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={10}>
        <Form.Item name="value" hasFeedback={false}>
          <Input
            size="large"
            name="value"
            placeholder={placeholder}
            disabled={valueDisabled}
          />
        </Form.Item>
      </Col>
    </>
  );
}

/**
 * Component to display the appropriate form for the confirmation mapping type
 */
export default function ConfirmationMappingTypes({
  mapping,
  onSubmit,
  mappings,
}: {
  mapping: Mapping;
  onSubmit: (values: FormValues) => void;
  mappings: Mapping[];
}) {
  const { t } = useTranslation();
  const getSchema = useCallback(() => {
    switch (mapping.confirmationType) {
      case "Grouping":
      case "Status":
      case "Message":
        return simpleSchemaBuilder([
          { name: "key", required: true, type: "string" },
        ]);
      case "Mapping":
      case "Text":
        return simpleSchemaBuilder([
          { name: "key", required: true, type: "string" },
          { name: "value", required: true, type: "string" },
        ]);
      default:
        return simpleSchemaBuilder([
          { name: "key", required: true, type: "string" },
        ]);
    }
  }, [mapping.confirmationType]);
  return (
    <Formik
      enableReinitialize
      initialValues={mapping}
      onSubmit={onSubmit}
      validationSchema={getSchema()}
    >
      {({ dirty, isValid }) => (
        <Form>
          <Row justify="start" gutter={16}>
            {mapping.confirmationType === "Text" && (
              <TextFieldType
                title={t("translation:text_field")}
                valueDisabled={false}
              />
            )}
            {mapping.confirmationType === "Status" && (
              <StatusFieldType title={t("translation:status_field")} />
            )}
            {mapping.confirmationType === "Message" && (
              <TextFieldType
                title={t("translation:message_field")}
                valueDisabled
                placeholder={t("translation:message_field_expanded")}
              />
            )}
            {mapping.confirmationType === "Grouping" && <GroupingType />}
            {mapping.confirmationType === "Mapping" && (
              <MappingTypes mappings={mappings} />
            )}
            <Col flex="auto">
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
}
