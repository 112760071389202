import { Button, Col, Row } from "antd";
import { Mapping, Setting } from "/app/src/models";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Form, Select, SubmitButton } from "formik-antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { simpleSchemaBuilder } from "/app/src/helpers";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { ValueField } from "./valueField";

interface FormValues {
  name?: string;
  value?: string;
}
/**
 *
 * @param param0 filter, updateSetting, deleteSetting, mappings
 * @returns List of filters
 */
export default function Filter({
  filter,
  updateSetting,
  deleteSetting,
  mappings,
}: {
  filter: Setting;
  updateSetting: (setting: Setting) => Promise<void>;
  deleteSetting: UseMutateAsyncFunction<
    { setting: { id: number } },
    unknown,
    number,
    unknown
  >;
  mappings: Mapping[];
}) {
  const { t } = useTranslation();
  const getMappingType = useCallback(
    (id: number) => {
      const mapping = mappings.find((mapping) => mapping.id === id);
      return mapping?.dataType;
    },
    [mappings],
  );
  const [mappingType, setMappingType] = useState<string>(
    getMappingType(Number(filter.name)),
  );

  const mapMappings = useCallback((mappings: Mapping) => {
    return (
      <Select.Option key={mappings.id} value={String(mappings.id)}>
        {mappings.key}
      </Select.Option>
    );
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await updateSetting({
        ...values,
        id: filter.id,
        integrationId: filter.integrationId,
        type: "sqlFilter",
      }).then(() => {
        actions.resetForm();
      });
    },
    [filter.id, filter.integrationId, updateSetting],
  );
  const handleSelectChange = useCallback(
    (value: string) => {
      //find the mapping with the id
      setMappingType(getMappingType(Number(value)));
    },
    [getMappingType],
  );

  const deleteSettingFn = useCallback(() => {
    deleteSetting(filter.id);
  }, [deleteSetting, filter.id]);

  const filterForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form>
          <Row justify="start" gutter={16}>
            <Col span={5}>
              <Form.Item name="name" hasFeedback={false}>
                <SearchFilter
                  name="name"
                  placeholder={t("translation:select_mapping")}
                  mapOptionsFn={mapMappings}
                  list={mappings}
                  sort
                  onChange={handleSelectChange}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item name="value" hasFeedback={false}>
                <ValueField mappingType={mappingType} />
              </Form.Item>
            </Col>
            <Col span={2}>
              <SubmitButton
                disabled={!dirty || !isValid}
                type="primary"
                size="large"
                block
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
            <Col span={2}>
              <Button
                onClick={deleteSettingFn}
                type="default"
                size="large"
                block
              >
                {t("translation:remove")}
              </Button>
            </Col>
          </Row>
        </Form>
      ),
      [
        deleteSettingFn,
        handleSelectChange,
        mapMappings,
        mappingType,
        mappings,
        t,
      ],
    );
  return (
    <div className="filter inList">
      <Formik
        component={filterForm}
        enableReinitialize
        initialValues={{
          name: filter.name,
          value: filter.value,
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
          { name: "value", type: "string", required: true },
        ])}
        onSubmit={onSubmit}
      />
    </div>
  );
}
