import { useCallback } from "react";
import { Row, Col, message, Button } from "antd";
import { hueService } from "/app/src/services";
import { useTranslation } from "react-i18next";

export default function NewIntegration() {
  const { t } = useTranslation();

  const connectBridge = useCallback(() => {
    hueService
      .getURL({})
      .then((response) => {
        if (response.errors) {
          message.error(response.errors[0]);
        } else {
          openURL(response.url);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="newIntegration">
      <Row justify="start">
        <Col span={12}>
          <Button type="primary" size="large" onClick={connectBridge}>
            {t("translation:add")} {t("translation:new_bridge")}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function openURL(url: string) {
  if (typeof window !== "undefined") {
    window.open(url);
  }
}
