import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Row, Col, Select } from "antd";
import { useDebounce } from "/app/src/hooks";
import Search from "../search";
import { locationService, zoneService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Location as LocationType } from "/app/src/models";
import { Zone as ZoneType } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";

const { Option } = Select;

export default function Locations() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fillFilter, setFillFilter] = useState("all");
  const [zoneFilter, setZoneFilter] = useState("all");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const [searchString, setSearchString] = useState("");

  const debouncedSearchTerm = useDebounce(searchString, 1000);

  const gotToLocation = (id: number) => {
    navigate(`/explorer/locations/${id}`);
  };

  const columnHelper = createColumnHelper<LocationType>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:name"),
      }),
      columnHelper.accessor("typeDescription", {
        id: "typeDescription",
        cell: (info) => info.getValue(),
        header: t("translation:type"),
      }),
      columnHelper.accessor("isMarkedFull", {
        id: "isMarkedFull",
        cell: (info) => {
          if (info.getValue() === 0) {
            return t("translation:no");
          }
          return t("translation:yes");
        },
        header: t("translation:marked_full"),
      }),
    ],
    [t, columnHelper],
  );

  const { data: zones } = useQuery({
    queryKey: ["zones"],
    queryFn: () => {
      return zoneService.getAll();
    },
    initialData: { zones: [] },
    select: (data: { zones: ZoneType[] }) => {
      return data.zones;
    },
  });

  const { data: locations, isFetching } = useQuery({
    queryKey: [
      "locations",
      page,
      pageSize,
      debouncedSearchTerm,
      fillFilter,
      zoneFilter,
      sort,
    ],
    queryFn: () => {
      return locationService.getAll(
        buildParams({
          limit: pageSize,
          page: page,
          orderby: getOrderByQuery(sort),
          search: debouncedSearchTerm,
          isMarkedFull: fillFilter,
          zoneId: zoneFilter,
        }),
      );
    },
    initialData: { locations: [] },
    select: (data: { locations: LocationType[] }) => {
      return data.locations;
    },
  });

  const { data: locationsCount } = useQuery({
    queryKey: ["locationsCount", searchString, zoneFilter, fillFilter],
    queryFn: () => {
      return locationService.getCount(
        buildParams({
          search: searchString,
          zoneId: zoneFilter,
          isMarkedFull: fillFilter,
        }),
      );
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
  });

  return (
    <div className="explorer">
      <Helmet>
        <title>{t("translation:locations")} - ItemPath</title>
      </Helmet>
      <Row className="box">
        <Col span={12}>
          <Search setSearchString={setSearchString} />
        </Col>
        <Col span={6}>
          <Select
            onChange={(value) => {
              setZoneFilter(value);
            }}
            size="large"
            style={{ width: "100%" }}
            placeholder={t("translation:filter_by_zone")}
          >
            <Option value="all">{t("translation:all")}</Option>
            {zones.map((zone) => (
              <Option value={zone.id} key={zone.id}>
                {zone.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            onChange={(value) => {
              setFillFilter(value);
            }}
            size="large"
            style={{ width: "100%" }}
            placeholder={t("translation:filter_by_marked_full")}
          >
            <Option value="all">{t("translation:all")}</Option>
            <Option value={1} key={1}>
              {t("translation:full")}
            </Option>
            <Option value={0} key={0}>
              {t("translation:not_full")}
            </Option>
          </Select>
        </Col>
      </Row>
      <TableUpgrade
        loading={isFetching}
        rows={locations}
        tableColumns={columns}
        length={locationsCount}
        sort={sort}
        setSort={setSort}
        paginationEnabled={{
          currentPage: page,
          pageSize: pageSize,
          setPage: setPage,
          setPageSize: setPageSize,
        }}
        enableRowSelection
        rowClicked={gotToLocation}
        emptyText={t("translation:no_locations_found")}
      />
    </div>
  );
}
