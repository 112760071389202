import { Button } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

interface FormValues {
  value: string | undefined;
}

/**
 *
 * @returns DynamicButtons component
 */
export default function DynamicButtons({
  disabled = false,
}: {
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const { setFieldValue, values } = useFormikContext<FormValues>();

  /**
   * Function to add lastDay to the value
   */
  const onClickLastDay = useCallback(() => {
    const currentValue = values.value ?? "";
    setFieldValue("value", `${currentValue}{lastDay}`);
  }, [setFieldValue, values.value]);

  /**
   * Function to add lastWeek to the value
   */
  const onClickLastWeek = useCallback(() => {
    const currentValue = values.value ?? "";
    setFieldValue("value", `${currentValue}{lastWeek}`);
  }, [setFieldValue, values.value]);

  return (
    <div style={{ marginTop: "8px" }}>
      <Button
        onClick={onClickLastDay}
        disabled={disabled}
        style={{ marginRight: 8 }}
      >
        {t("translation:lastDay")}
      </Button>
      <Button onClick={onClickLastWeek} disabled={disabled}>
        {t("translation:lastWeek")}
      </Button>
    </div>
  );
}
