import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SettingsMenu({
  appSettingsCount,
}: {
  appSettingsCount: number;
}) {
  const { t } = useTranslation();

  return (
    <div className="settingsMenu sideMenu">
      <div className="box">
        <ul>
          <li>
            <NavLink end to="/settings">
              {t("translation:general")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/kardex">{t("translation:kardex")}</NavLink>
          </li>
          <li>
            <NavLink to="/settings/connections">
              {t("translation:connections")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/settings/report_column_types">
              {t("translation:custom_columns")}
            </NavLink>
          </li>
          {appSettingsCount > 0 && (
            <li>
              <NavLink to="/settings/apps">{t("translation:apps")}</NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
