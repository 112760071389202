import React, { useCallback, useContext, useState } from "react";
import EditButton from "/app/src/components/generic/components/buttons/EditButton";
import saveIcon from "/app/src/components/generic/title/saveIcon.svg";
import { Formik, FormikProps } from "formik";
import { Form, SubmitButton, Input } from "formik-antd";
import { useNavigate } from "react-router-dom";
import { Modal, Row, Col } from "antd";
import { reportService, userReportService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { Report } from "/app/src/models";
import { useAuthState } from "/app/src/contexts/authentication";
import FavouriteStar from "/app/src/components/generic/components/favouriteStar";
import { useQueryClient } from "@tanstack/react-query";
import AccessContext from "/app/src/contexts/AccessContext";
import DeleteButton from "/app/src/components/generic/components/buttons/DeleteButton";
import { getTableName } from "/app/src/helpers/themes";
import { simpleSchemaBuilder } from "/app/src/helpers";
const { confirm } = Modal;

interface FormValues {
  name: string | undefined;
}

function ShowConfirm(
  okText: string,
  cancelText: string,
  title: string,
  content: string,
  onOk: () => void,
) {
  confirm({
    okText,
    okButtonProps: { danger: true },
    cancelText,
    title,
    content,
    onOk,
  });
}
/**
 * Compoments shows the title of the report and the controls for editing title, deleting report
 * and favouriting report
 * @param param0 report
 */
export default function Title({ report }: { report: Report }) {
  const navigate = useNavigate();
  const { user } = useAuthState();
  const [editingTitle, setEditingTitle] = useState(false);
  const { t } = useTranslation();
  const { canEdit, canDelete } = useContext(AccessContext);

  const queryClient = useQueryClient();

  /**
   * Handler for favouriting a report
   * @param reportId id of the report to favourite
   */
  const favouriteReport = React.useCallback(
    (reportId: number) => {
      userReportService.favouriteReport(user.id, reportId).then(() => {
        queryClient.setQueryData(["report", report.id], {
          report: {
            ...report,
            favouritedUsers: [...report.favouritedUsers, user.id],
          },
        });
      });
    },
    [queryClient, report, user.id],
  );
  /**
   * Handler for unfavouriting a report
   * @param reportId id of the report to unfavourite
   */
  const unfavouriteReport = React.useCallback(
    (reportId: number) => {
      userReportService.unfavouriteReport(user.id, reportId).then(() => {
        queryClient.setQueryData(["report", report.id], {
          report: {
            ...report,
            favouritedUsers: report.favouritedUsers.filter(
              (userId) => userId !== user.id,
            ),
          },
        });
      });
    },
    [queryClient, report, user.id],
  );

  const editTitleForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ isSubmitting }) => (
        <Form>
          <Row>
            <Col span={20}>
              <Form.Item name="name" hasFeedback={false}>
                <Input
                  type="text"
                  name="name"
                  className="titleInput" // skipcq: JS-0394
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <SubmitButton
                className="saveButton" // skipcq: JS-0394
                type="primary"
                size="large"
                block
                disabled={isSubmitting}
              >
                <img src={saveIcon} alt="Save" />
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [],
    );

  // Handler for clicking the edit button
  const handleEditClick = (): void => {
    setEditingTitle(true);
  };

  //Handler for clicking the delete button
  const deleteConfirmation = (): void => {
    reportService.deleteSingle(report.id).then(() => {
      navigate("/reports");
      queryClient.invalidateQueries({ queryKey: ["reports"] });
    });
  };
  const handleDeleteClick = (): void => {
    ShowConfirm(
      t("translation:delete"),
      t("translation:do_not_delete"),
      t("translation:confirm_delete_report"),
      t("translation:delete_report_workflows_tip"),
      deleteConfirmation,
    );
  };

  return (
    <div className="title">
      <Row>
        {editingTitle ? (
          <Formik
            component={editTitleForm}
            enableReinitialize
            initialValues={{
              name: report.name,
            }}
            validationSchema={simpleSchemaBuilder([
              { name: "name", type: "string", required: true },
            ])}
            onSubmit={(values, actions) => {
              if (report?.id) {
                reportService
                  .updateSingle(report.id, values)
                  .then((response) => {
                    actions.resetForm();
                    actions.setSubmitting(false);
                    setEditingTitle(false);
                    queryClient.setQueryData(["report", report.id], {
                      report: { ...report, name: response.report.name },
                    });
                  });
              } else {
                actions.setSubmitting(false);
              }
            }}
          />
        ) : (
          <div
            className="noEdit"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1>{report.name}</h1>
            <div
              className="titleActions"
              style={{ display: "flex", alignItems: "center" }}
            >
              {canEdit && <EditButton onClick={handleEditClick} />}
              {canDelete && <DeleteButton onClick={handleDeleteClick} />}
              <div
                className="favouriteButton"
                style={{ display: "flex", alignItems: "center" }}
              >
                <FavouriteStar
                  isFavourite={report.favouritedUsers.includes(user.id)}
                  objectId={report.id}
                  favouriteFn={favouriteReport}
                  unfavouriteFn={unfavouriteReport}
                />
              </div>
            </div>
          </div>
        )}
      </Row>
      <Row>
        {/* Add space between before capital letters of theme */}
        <h4>
          {t("translation:theme")}: {getTableName(report.baseTable)}
        </h4>
      </Row>
    </div>
  );
}
