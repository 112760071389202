import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Select } from "formik-antd";
import { Integration, MaterialProperty } from "/app/src/models";
import { simpleSchemaBuilder } from "/app/src/helpers";

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ToolTip from "/app/src/components/generic/components/toolTip";
import useSetting from "/app/src/components/settings/setting";
import { useQuery } from "@tanstack/react-query";
import { materialPropertyService } from "/app/src/services";

interface FormValues {
  value: string | undefined;
}

/**
 * Component to display the default direction type setting for a single Data Pull Integration
 */
export default function DefaultMaterialProperty({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const { settingValue, createUpdateSetting } = useSetting({
    integrationId: integration.id,
    name: "defaultMaterialProperty",
  });

  const { data: materialProperties } = useQuery({
    queryKey: ["materialProperties"],
    queryFn: () => {
      return materialPropertyService.getAll();
    },
    initialData: { material_properties: [] },
    select: (data: { material_properties: MaterialProperty[] }) => {
      return data.material_properties;
    },
  });

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await createUpdateSetting({
        ...values,
        name: "defaultMaterialProperty",
        integrationId: integration.id,
      }).then(() => {
        actions.resetForm();
      });
    },
    [createUpdateSetting, integration.id],
  );
  const labelContent = useMemo(
    () => (
      <>
        {t("translation:default_material_property")}
        <ToolTip tip={t("translation:default_material_property_description")} />
      </>
    ),
    [t],
  );

  const form: (props: FormikProps<FormValues>) => JSX.Element = useCallback(
    ({ isSubmitting, dirty }) => (
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="value" label={labelContent}>
              <Select name="value">
                {materialProperties.map((materialProperty) => (
                  <Select.Option
                    key={materialProperty.id}
                    value={materialProperty.id}
                  >
                    {materialProperty.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:save")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [labelContent, materialProperties, t],
  );
  return (
    <Formik
      initialValues={settingValue ? { value: settingValue } : { value: "" }}
      validationSchema={simpleSchemaBuilder([
        { name: "value", type: "string", required: true },
      ])}
      onSubmit={onSubmit}
      component={form}
      enableReinitialize
    />
  );
}
