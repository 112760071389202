import React, { useCallback } from "react";
import { Row, Col, Button } from "antd";
import { Form, Input, SubmitButton, Cascader, Select } from "formik-antd";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "/app/src/components/generic/components/searchFilter";
import { Mapping, ReportColumnType } from "/app/src/models";
import { cascadeReportColumnTypes } from "/app/src/helpers/cascadeReportColumnTypes";

/**
 * Component for a column type mapping.
 */
export function ColumnType({
  isThemed,
  columnTypes,
  mapping,
  removeMapping,
  dirty,
  isValid,
}: {
  isThemed: boolean;
  columnTypes: ReportColumnType[];
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
}) {
  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);
  const { t } = useTranslation();
  return (
    <Row justify="start" gutter={16}>
      {mapping ? (
        <>
          <Col span={2}>
            {t("translation:column")} {t("translation:mapping")}:
          </Col>
          <Col span={6}>
            <Form.Item name="key" hasFeedback={false}>
              <Input
                size="large"
                name="key"
                placeholder={t("translation:enter_key")}
              />
            </Form.Item>
          </Col>
        </>
      ) : (
        <Col span={8}>
          <Form.Item name="key" hasFeedback={false}>
            <Input
              size="large"
              name="key"
              placeholder={t("translation:enter_key")}
            />
          </Form.Item>
        </Col>
      )}

      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={8}>
        <Form.Item name="columnTypeId" hasFeedback={false}>
          {isThemed ? (
            <Cascader
              name="columnTypeId"
              size="large"
              className="larger-cascade"
              options={cascadeReportColumnTypes(columnTypes)}
              placeholder={t("translation:select_column")}
              showSearch
            />
          ) : (
            <SearchFilter
              list={columnTypes}
              name="columnTypeId"
              placeholder={t("translation:select_column")}
            />
          )}
        </Form.Item>
      </Col>
      <Col flex="auto">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
      </Col>
      {mapping && (
        <Col span={3}>
          <Button
            onClick={removeMappingHandler}
            type="default"
            size="large"
            block
          >
            {t("translation:remove")}
          </Button>
        </Col>
      )}
    </Row>
  );
}

/**
 * Component for customer's internal id mapping.
 */
export function InternalIdType({
  mapping,
  removeMapping,
  dirty,
  isValid,
}: {
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
}) {
  const { t } = useTranslation();
  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);
  return (
    <Row justify="start" gutter={16}>
      {mapping ? (
        <>
          <Col span={2}>{"ID Mapping:"}</Col>
          <Col span={6}>
            <Form.Item name="key" hasFeedback={false}>
              <Input
                size="large"
                name="key"
                placeholder={t("translation:enter_key")}
              />
            </Form.Item>
          </Col>
        </>
      ) : (
        <Col span={8}>
          <Form.Item name="key" hasFeedback={false}>
            <Input
              size="large"
              name="key"
              placeholder={t("translation:enter_key")}
            />
          </Form.Item>
        </Col>
      )}

      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={8}>
        <Form.Item name="value" hasFeedback={false}>
          <Input
            disabled
            size="large"
            name="value"
            placeholder={t("translation:internal_id")}
          />
        </Form.Item>
      </Col>
      <Col flex="auto">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
      </Col>
      {mapping && (
        <Col span={3}>
          <Button
            onClick={removeMappingHandler}
            type="default"
            size="large"
            block
          >
            {t("translation:remove")}
          </Button>
        </Col>
      )}
    </Row>
  );
}

/**
 * Component for a grouping type mapping. This is useful for nested data.
 */
export function GroupingType({ dirty }: { dirty: boolean }) {
  const { t } = useTranslation();
  return (
    <Row justify="start" gutter={16}>
      <Col span={18}>
        <Form.Item name="key" hasFeedback={false}>
          <Input
            size="large"
            name="key"
            placeholder={t("translation:enter_key")}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <SubmitButton type="primary" size="large" block disabled={!dirty}>
          {t("translation:save_mapping")}
        </SubmitButton>
      </Col>
    </Row>
  );
}
/**
 * Component for an advanced type mapping. This is useful for amalgamating multiple keys into a single column.
 */
export function AdvancedType({
  mapping,
  removeMapping,
  dirty,
  isValid,
  isThemed,
  columnTypes,
}: {
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
  isThemed: boolean;
  columnTypes: ReportColumnType[];
}) {
  const { t } = useTranslation();
  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);
  return (
    <Row justify="start" gutter={16}>
      {mapping ? (
        <>
          <Col span={2}>{t("translation:advanced_mapping")}:</Col>
          <Col span={6}>
            <Form.Item name="key" hasFeedback={false}>
              <Select
                name="key"
                size="large"
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Add keys"
              />
            </Form.Item>
          </Col>
        </>
      ) : (
        <Col span={8}>
          <Form.Item name="key" hasFeedback={false}>
            <Select
              name="key"
              size="large"
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add keys"
            />
          </Form.Item>
        </Col>
      )}
      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={8}>
        <Form.Item name="columnTypeId" hasFeedback={false}>
          {isThemed ? (
            <Cascader
              name="columnTypeId"
              size="large"
              className="larger-cascade"
              options={cascadeReportColumnTypes(columnTypes)}
              placeholder={t("translation:select_column")}
              showSearch
            />
          ) : (
            <SearchFilter
              list={columnTypes}
              name="columnTypeId"
              placeholder={t("translation:select_column")}
            />
          )}
        </Form.Item>
      </Col>
      <Col flex="auto">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
      </Col>
      {mapping && (
        <Col span={3}>
          <Button
            onClick={removeMappingHandler}
            type="default"
            size="large"
            block
          >
            {t("translation:remove")}
          </Button>
        </Col>
      )}
    </Row>
  );
}

/**
 * Component for a text type mapping.
 */
export function TextType({
  mapping,
  removeMapping,
  dirty,
  isValid,
}: {
  mapping?: Mapping;
  removeMapping?: (mapping: Mapping) => void;
  dirty: boolean;
  isValid: boolean;
}) {
  const { t } = useTranslation();
  /**
   * Remove the mapping. This is only available when editing a mapping.
   */
  const removeMappingHandler = useCallback(() => {
    if (removeMapping) {
      removeMapping(mapping);
    }
  }, [removeMapping, mapping]);
  return (
    <Row justify="start" gutter={16}>
      {mapping ? (
        <>
          <Col span={2}>{t("translation:text_mapping")}:</Col>
          <Col span={6}>
            <Form.Item name="key" hasFeedback={false}>
              <Input
                size="large"
                name="key"
                placeholder={t("translation:enter_key")}
              />
            </Form.Item>
          </Col>
        </>
      ) : (
        <Col span={8}>
          <Form.Item name="key" hasFeedback={false}>
            <Input
              size="large"
              name="key"
              placeholder={t("translation:enter_key")}
            />
          </Form.Item>
        </Col>
      )}

      <Col span={2}>
        <div className="arrowRight" />
      </Col>
      <Col span={8}>
        <Form.Item name="value" hasFeedback={false}>
          <Input
            size="large"
            name="value"
            placeholder={t("translation:enter_value")}
          />
        </Form.Item>
      </Col>
      <Col flex="auto">
        <SubmitButton
          type="primary"
          size="large"
          block
          disabled={!dirty || !isValid}
        >
          {t("translation:save")}
        </SubmitButton>
      </Col>
      {mapping && (
        <Col span={3}>
          <Button
            onClick={removeMappingHandler}
            type="default"
            size="large"
            block
          >
            {t("translation:remove")}
          </Button>
        </Col>
      )}
    </Row>
  );
}
