import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import PowerPickTimeZone from "./powerPickTimeZone";
import TimeZone from "./timeZone";
import DefaultWarehouse from "./warehouse";
import License from "./license";
import Url from "./url";
import DefaultMaterialProperty from "./materialProperty";

/**
 * Component to display the forms for updating the license, url, time zone, and default warehouse
 */
export default function General() {
  const { t } = useTranslation();
  return (
    <div className="box">
      <h1>{t("translation:general_settings")}</h1>
      <Divider />
      <Url />
      <Divider />
      <License />
      <Divider />
      <TimeZone />
      <Divider />
      <PowerPickTimeZone />
      <Divider />
      <DefaultWarehouse />
      <Divider />
      <DefaultMaterialProperty />
    </div>
  );
}
