import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Row, Spin, Skeleton } from "antd";
import Controls from "./_controls";
import InnerControls from "./_innerControls";
import { orderService, processService } from "/app/src/services";
import { useDebounce, useLoading } from "/app/src/hooks";
import { integrationService } from "/app/src/services";
import { useTranslation } from "react-i18next";
import { App, Order } from "/app/src/models";
import getOrderByQuery from "/app/src/helpers/table";
import { buildParams } from "/app/src/helpers/params";
import { createColumnHelper } from "@tanstack/react-table";
import TableUpgrade from "/app/src/components/generic/tables/tableUpgrade";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import IndeterminateCheckbox from "/app/src/components/generic/tables/indeterminateCheckbox";

export default function OrdersList({
  integrationId,
  app,
}: {
  integrationId: number;
  app: App;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const debouncedSearchTerm = useDebounce(searchString, 1000);
  const [orders, setOrders] = useState<Order[]>([]);
  const [length, setLength] = useState<number>(0);
  const [sort, setSort] = useSortUpgrade([]);
  const [loading, setLoading] = useLoading();
  const [selectedOrders, setSelectedOrders] = useState<Record<number, boolean>>(
    {},
  );
  const [selected, setSelected] = useState<string[]>([]);
  const [directionType, setDirectionType] = useState(2);
  const [countEnabled, setCountEnabled] = useState(true);
  const [integrationName, setIntegrationName] = useState("");
  const [loadingProcess, setLoadingProcess] = useState(false);

  const columnHelper = createColumnHelper<Order>();
  const columns = useMemo(() => {
    const baseColumns = [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        meta: {
          clickable: false,
        },
      },
      columnHelper.accessor("name", {
        id: "name",
        cell: (info) => info.getValue(),
        header: t("translation:order_name"),
      }),
      columnHelper.accessor("Info5", {
        id: "Info5",
        cell: (info) => info.getValue(),
        header: t("translation:type"),
      }),
      columnHelper.accessor("Info1", {
        id: "Info1",
        cell: (info) => info.getValue(),
        header: t("translation:part_number"),
      }),
    ];
    if (!countEnabled) {
      columns.push(
        columnHelper.accessor("count", {
          id: "count",
          cell: (info) => info.getValue(),
          header: t("translation:count"),
        }),
      );
    }
    return baseColumns;
  }, [columnHelper, countEnabled, t]);

  useEffect(() => {
    integrationService
      .getSingle(integrationId)
      .then((response) => {
        if (response.integration.name === "Manual Processing") {
          setCountEnabled(true);
        }
        setIntegrationName(response.integration.name);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [integrationId]);

  useEffect(() => {
    const getOrders = () => {
      return orderService
        .getAll(
          buildParams({
            state: "queued",
            directionType: directionType,
            search: debouncedSearchTerm,
            orderby: getOrderByQuery(sort),
          }),
        )
        .then((response) => {
          const filtered = filterOutInvalidOrders(response.orders);

          setOrders(filtered);
          setLength(filtered.length);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    setLoading(getOrders);
  }, [debouncedSearchTerm, directionType, countEnabled, setLoading, sort]);

  const forwardOrders = () => {
    setLoadingProcess(true);
    processService.createSingle({ orders: selected }).then((response) => {
      navigate(
        `/apps/${app.id}/integrations/${integrationId}/processes/${response.process.id}`,
      );
    });
  };

  useEffect(() => {
    const tempOrders: string[] = [];
    const keys = Object.keys(selectedOrders);
    keys.forEach((element) => {
      const currentOrder = orders.at(Number(element));
      if (currentOrder?.id) {
        tempOrders.push(currentOrder.id);
      }
    });
    setSelected(tempOrders);
  }, [selectedOrders, orders]);

  return (
    <div className="app orderProcessor">
      <Helmet>
        <title>{app.name} - ItemPath</title>
      </Helmet>
      <Controls app={app} name={integrationName} />
      {loadingProcess ? (
        <Spin>
          <Skeleton title={false} paragraph={{ rows: 14, width: 100 }} />
        </Spin>
      ) : (
        <Row gutter={20}>
          <Col span={24}>
            <Row gutter={20}>
              <Col span={24}>
                <InnerControls
                  count={Object.keys(selectedOrders).length}
                  directionType={directionType}
                  setDirectionType={setDirectionType}
                  setSearchString={setSearchString}
                  processOrders={forwardOrders}
                  countTypeEnabled={countEnabled}
                />
              </Col>
            </Row>
            <TableUpgrade
              rows={orders}
              tableColumns={columns}
              length={length}
              sort={sort}
              setSort={setSort}
              loading={loading}
              enableRowSelection
              setSelectedRows={setSelectedOrders}
              emptyText={t("translation:no_orders_found")}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}
function filterOutInvalidOrders(listOfOrders: Order[]) {
  const filtered: Order[] = [];
  listOfOrders.forEach((element) => {
    let valid = false;
    if (element.order_lines) {
      element.order_lines.forEach((orderline) => {
        // Serial Number check on Info1 for Photon
        if (orderline.Info1 === "True" || orderline.serialNumber !== null) {
          valid = true;
        }
      });
    }
    if (valid && element.order_lines) {
      element.count = element.order_lines.length;
      filtered.push(element);
    }
  });
  return filtered;
}
