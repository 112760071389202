import { useFormikContext } from "formik";
import ConnectionType from "./connectionType";

interface FormValues {
  name: string | undefined;
  type: string[] | undefined;
  url: string | undefined;
  auth: string | undefined;
  username: string | undefined;
  password: string | undefined;
  token: string | undefined;
}
/**
 * Formik Wrapper for ConnectionType. Uses the formik context to get the values
 * for determining which fields to display
 */
export default function FormikTypeWrapper() {
  const { values } = useFormikContext<FormValues>();
  return (
    <ConnectionType
      includeAuth={
        !values.type?.includes("No Auth") && !values.type?.includes("OAuth1")
      }
      includeDatabase={
        values.type?.includes("MSSQL") || values.type?.includes("IBM2")
      }
      hidePassword
      hideToken
    />
  );
}
