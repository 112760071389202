import React, { useCallback, useMemo } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col, Switch } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

import { integrationService, connectionService } from "/app/src/services";
import Headers from "/app/src/components/generic/components/apps/data/settings/headers";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import ConnectionSettings from "/app/src/components/generic/components/apps/data/settings/connectionSettings";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { isAPIType, isSQLType, simpleSchemaBuilder } from "/app/src/helpers";
import Request from "./request";
import AutoDetectDirection from "./autoDetectDirection";
import DefaultMaterialProperty from "./defaultMaterialProperty";
import SQLGroupBy from "./sqlGroupBy";
import DateTimeFormat from "./dateTimeFormat";
import Filters from "./filters";
import DefaultDirection from "./defaultDirection";
import AppendLines from "./appendLines";
import EnableManual from "./enableManual";
/**
 * Format the form value keys to match the API Model
 */
function formatForm(values: IntegrationFormValues): Integration {
  const ret = {
    name: values.name,
    baseTable: values.baseTable,
  };
  if (values.token) {
    ret["token"] = values.token;
  }
  return ret;
}

interface IntegrationFormValues {
  name: string | undefined;
  baseTable: string | undefined;
  token?: string;
}

/**
 * Component to display the settings for a single Data Pull Integration
 */
export default function Settings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (updatedIntegration: Integration) => {
      return integrationService
        .updateSingle(updatedIntegration.id, updatedIntegration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });
  const baseTables = useMemo(
    () => [
      { label: "Material Creation", value: "Material" },
      { label: "Order Creation", value: "Order" },
    ],
    [],
  );
  /**
   * Update the integration
   */
  const updateIntegrationHandler = useCallback(
    async (
      values: IntegrationFormValues,
      actions: FormikHelpers<IntegrationFormValues>,
    ) => {
      if (integration?.id) {
        await updateIntegration({
          id: integration.id,
          ...formatForm(values),
        }).then(() => {
          actions.resetForm();
        });
      }
    },
    [integration.id, updateIntegration],
  );

  /**
   * Toggle the integration status
   */
  const toggleIntegrationStatus = useCallback(
    (value: boolean) => {
      //value is a bool - convert to number
      updateIntegration({ id: integration.id, status: Number(value) });
    },
    [integration, updateIntegration],
  );

  const { data: connections } = useQuery({
    queryKey: ["connections", "[or]Basic;No Auth;MSSQL;OAuth1;IBM2"],
    queryFn: () => {
      return connectionService.getAll(
        buildParams({ type: "[or]Basic;No Auth;MSSQL;OAuth1;IBM2" }),
      );
    },
    initialData: { connections: [] },
    select: (data = { connections: [] }) => {
      return data.connections;
    },
  });

  /*
   * Formik form for updating the integration
   */
  const updateIntegrationForm: (
    props: FormikProps<IntegrationFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={4}>
            <Form.Item
              name="name"
              label={t("translation:integration_name")}
              hasFeedback={false}
            >
              <Input
                name="name"
                placeholder={t("translation:enter_name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="baseTable" label={t("translation:type")}>
              <Select
                disabled
                name="baseTable"
                size="large"
                placeholder={t("translation:select_type")}
              >
                {baseTables.map((t) => (
                  <Select.Option value={t.value} key={t.value}>
                    {t.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {isSQLType(integration.connectionType) && (
            <Col span={6}>
              <Form.Item
                name="token"
                label={t("translation:table_name")}
                hasFeedback={false}
              >
                <Input
                  name="token"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:update")} {t("translation:integration")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [baseTables, integration.connectionType, t],
  );

  /**
   * Toggle the integration status
   */
  return (
    <>
      <div className="box">
        <Row>
          <Col span={23}>
            <h1>{t("translation:general")}</h1>
          </Col>
          <Col span={1}>
            <h4>{t("translation:active")}:</h4>
            <Switch
              className="toggleSwitch"
              onChange={toggleIntegrationStatus}
              checked={integration?.status === 1}
            />
          </Col>
        </Row>
        <Formik
          component={updateIntegrationForm}
          initialValues={{
            name: integration.name,
            baseTable: integration.baseTable,
            token: integration.token,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
            { name: "baseTable", type: "string", required: true },
          ])}
          enableReinitialize
          onSubmit={updateIntegrationHandler}
        />
      </div>

      <ConnectionSettings
        integration={integration}
        maxRecords={false}
        retries={false}
        connections={connections}
      />
      {isAPIType(integration.connectionType) && (
        <>
          <Headers integration={integration} />
          <Request integration={integration} />
        </>
      )}
      <div className="box">
        <h1>{t("translation:other_settings")}</h1>
        <DateTimeFormat integration={integration} />
        {integration.baseTable === "Order" && (
          <>
            <AutoDetectDirection integration={integration} />
            <DefaultDirection integration={integration} />
            <AppendLines integration={integration} />
          </>
        )}
        {integration.baseTable === "Material" && (
          <DefaultMaterialProperty integration={integration} />
        )}
        <EnableManual integration={integration} />
        {isSQLType(integration.connectionType) && (
          <SQLGroupBy integration={integration} />
        )}
      </div>
      {isSQLType(integration.connectionType) && (
        <Filters integration={integration} />
      )}
    </>
  );
}
