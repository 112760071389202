import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { Row, Col } from "antd";
import { integrationService } from "/app/src/services";
import SideMenuBox from "/app/src/components/generic/components/sideMenuBox";
import Settings from "/app/src/components/apps/orderBuilder/settings/settings";
import OrderBuilder from "./builder";
import { App, Integration as IntegrationType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { useQuery } from "@tanstack/react-query";

export default function Integration({ app }: { app: App }) {
  const { integrationId } = useNumberId();

  // Get integration
  const { data: integration } = useQuery({
    queryKey: ["integration", integrationId],
    queryFn: () => {
      return integrationService.getSingle(integrationId);
    },
    enabled: Boolean(integrationId),
    initialData: { integration: {} },
    select: (data: { integration: IntegrationType }) => {
      return data.integration;
    },
  });

  const links = [
    {
      path: `/apps/${app.id}/integrations/${integration.id}`,
      label: "builder",
    },
    {
      path: `/apps/${app.id}/integrations/${integration.id}/settings`,
      label: "settings",
    },
  ];

  return (
    <div className="integration">
      <Helmet>
        <title>{integration.name}</title>
      </Helmet>

      <Row gutter={20}>
        <Col span={6}>
          <SideMenuBox links={links} />
        </Col>
        <Col span={18}>
          {integration.id && (
            <Routes>
              <Route path="/" element={<OrderBuilder app={app} />} />
              <Route
                path="settings"
                element={<Settings integration={integration} />}
              />
            </Routes>
          )}
        </Col>
      </Row>
    </div>
  );
}
