import React, { useCallback, useMemo } from "react";
import { Form, SubmitButton, Input, Cascader } from "formik-antd";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { newConnectionSchema } from "/app/src/schemas";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { handleSubmissionErrors } from "/app/src/helpers/forms";
import { Connection } from "/app/src/models";
import FormikTypeWrapper from "./connectionType/formikTypeWrapper";

interface FormValues {
  name: string | undefined;
  type: string[] | undefined;
  url: string | undefined;
  auth: string | undefined;
  username: string | undefined;
  password: string | undefined;
  token: string | undefined;
}
interface Option {
  value: string;
  label: string;
  children?: Option[];
}

export default function NewConnection({
  toggleNew,
  addConnection,
}: {
  toggleNew: () => void;
  addConnection: (
    connection: Connection,
  ) => Promise<{ connection: Connection }>;
}) {
  const { t } = useTranslation();
  const options: Option[] = useMemo(
    () => [
      {
        value: "API",
        label: "API",
        children: [
          {
            value: "Basic",
            label: "Basic",
          },
          {
            value: "No Auth",
            label: "No Auth",
          },
        ],
      },
      {
        value: "Netsuite",
        label: "Netsuite",
        children: [
          {
            value: "OAuth1",
            label: "OAuth 1.0",
          },
        ],
      },
      {
        value: "PLC",
        label: "PLC",
      },
      {
        value: "SQL",
        label: "SQL",
        children: [
          { value: "MSSQL", label: "MSSQL" },
          { value: "IBM2", label: "IBM DB2" },
        ],
      },
    ],
    [],
  );

  const newConnectionForm: (props: FormikProps<FormValues>) => JSX.Element =
    useCallback(
      ({ dirty, isValid }) => (
        <Form layout="vertical">
          <Row>
            <Col span={22}>
              <h3>
                {t("translation:create")} {t("translation:new_connection")}
              </h3>
            </Col>
            <Col span={2}>
              <CloseOutlined
                onClick={toggleNew}
                style={{ float: "right", color: "red" }}
              />
            </Col>
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label={t("translation:name")}>
                <Input suffix name="name" className="user" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type" label={t("translation:type")}>
                <Cascader name="type" size="large" options={options} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="url" label={t("translation:address")}>
                <Input suffix name="url" className="url" size="large" />
              </Form.Item>
            </Col>
            <FormikTypeWrapper />
          </Row>
          <Row justify="start" gutter={16}>
            <Col span={24}>
              <SubmitButton
                type="primary"
                size="large"
                block
                disabled={!dirty || !isValid}
              >
                {t("translation:save")}
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      ),
      [options, t, toggleNew],
    );
  const initialFormValues: FormValues = {
    username: "",
    password: "",
    url: "",
    name: "",
    type: [],
    auth: "",
    token: "",
  };

  return (
    <div className="box">
      <Formik
        component={newConnectionForm}
        validationSchema={newConnectionSchema}
        initialValues={initialFormValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          addConnection(formatForm(values))
            .then(() => {
              toggleNew();
            })
            .catch((errors) => {
              handleSubmissionErrors(errors, actions.setFieldError);
            });
        }}
      />
    </div>
  );
}

function formatForm(values: FormValues) {
  return {
    username: values.username,
    password: values.password,
    url: values.url,
    name: values.name,
    type: values.type[values.type.length - 1],
    token: values.token,
  };
}
