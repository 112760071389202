import React, { useCallback } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { Row, Col } from "antd";
import { Form, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";

import { integrationService } from "/app/src/services";
import { Integration } from "/app/src/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { simpleSchemaBuilder } from "/app/src/helpers";

/**
 * Format the form value keys to match the API Model
 */
function formatForm(values: IntegrationFormValues): Integration {
  return {
    name: values.name,
  };
}

interface IntegrationFormValues {
  name: string | undefined;
}

/**
 * Component to display the settings for a single Data Pull or Data Push Integration
 */
export default function Settings({
  integration,
  children = null,
}: {
  integration: Integration;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (updatedIntegration: Integration) => {
      return integrationService
        .updateSingle(updatedIntegration.id, updatedIntegration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });

  /**
   * Update the integration
   */
  const updateIntegrationHandler = useCallback(
    async (
      values: IntegrationFormValues,
      actions: FormikHelpers<IntegrationFormValues>,
    ) => {
      if (integration?.id) {
        await updateIntegration({
          id: integration.id,
          ...formatForm(values),
        }).then(() => {
          actions.resetForm();
        });
      }
    },
    [integration.id, updateIntegration],
  );

  const updateIntegrationForm: (
    props: FormikProps<IntegrationFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={8}>
            <Form.Item
              name="name"
              label={t("translation:integration_name")}
              hasFeedback={false}
            >
              <Input
                name="name"
                placeholder={t("translation:enter_name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting}
              style={{ marginTop: "30px" }}
            >
              {t("translation:update")} {t("translation:integration")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [t],
  );

  return (
    <>
      <div className="box">
        <Row>
          <Col span={24}>
            <h1>{t("translation:general")}</h1>
          </Col>
        </Row>
        <Formik
          component={updateIntegrationForm}
          initialValues={{
            name: integration.name,
          }}
          validationSchema={simpleSchemaBuilder([
            { name: "name", type: "string", required: true },
          ])}
          enableReinitialize
          onSubmit={updateIntegrationHandler}
        />
      </div>
      {children}
    </>
  );
}
