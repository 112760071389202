import { Formik, FormikHelpers } from "formik";
import { Integration } from "/app/src/models";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { settingService } from "/app/src/services";
import { buildParams, simpleSchemaBuilder } from "/app/src/helpers";
import { handlePromiseError } from "/app/src/helpers/api";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import RequestForm from "./requestForm";

interface FormValues {
  value: string | undefined;
  name: string | undefined;
}

/**
 * Component to display the request settings for a single Data Pull Integration
 * The request settings are the method and body of the request. Current available
 * methods are GET and POST
 */
export default function Request({ integration }: { integration: Integration }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ["setting", "request", integration.id],
    queryFn: () =>
      settingService.getAll(
        buildParams({ integrationId: integration.id, type: "request" }),
      ),
  });

  const { mutateAsync } = useMutation({
    mutationFn: (values: FormValues) => {
      if (data?.settings.length < 1) {
        return settingService
          .createSingle({
            name: values.name,
            value: values.value,
            type: "request",
            integrationId: integration.id,
          })
          .then(handlePromiseError);
      } else {
        return settingService
          .updateSingle(data?.settings[0]?.id, {
            value: values.value,
          })
          .then(handlePromiseError);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["setting", "request", integration.id]);
    },
  });

  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      await mutateAsync(values).then(() => {
        actions.resetForm();
      });
    },
    [mutateAsync],
  );

  return (
    <div className="box">
      <h1>{t("translation:request")}</h1>
      <Formik
        initialValues={{
          value: data?.settings[0]?.value,
          name: data?.settings[0]?.name ? data?.settings[0]?.name : "GET",
        }}
        validationSchema={simpleSchemaBuilder([
          { name: "name", type: "string", required: true },
        ])}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <RequestForm />
      </Formik>
    </div>
  );
}
