import { Select, Form } from "formik-antd";
import { useCallback, useState } from "react";
import { ReportColumn } from "/app/src/models";
import { useTranslation } from "react-i18next";
import { Col } from "antd";

/**
 * Component for comparing two columns
 */
export default function Comparison({ columns }: { columns: ReportColumn[] }) {
  const { t } = useTranslation();
  const [columnType, setColumnType] = useState<string | undefined>(undefined);
  /**
   * Function to update the column type state
   */
  const onChange = useCallback(
    (colId) => {
      if (colId === 0) {
        return;
      }
      //find the column  by id
      const column = columns.find((col) => col.id === colId);
      if (column) {
        setColumnType(column.filterType);
      }
    },
    [columns],
  );

  return (
    <>
      <Col span={5}>
        <Form.Item name="firstColumn">
          <Select
            name="firstColumn"
            size="large"
            placeholder={t("translation:select_column")}
            onChange={onChange}
          >
            {columns.map((column) => (
              <Select.Option key={column.id} value={column.id}>
                {column.name}
              </Select.Option>
            ))}
            {columnType === "DateTime" && (
              <Select.Option value={0}>
                {t("translation:current_day")}
              </Select.Option>
            )}
          </Select>
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item name="secondColumn">
          <Select
            name="secondColumn"
            size="large"
            placeholder={t("translation:select_column")}
            onChange={onChange}
          >
            {columns.map((column) => (
              <Select.Option key={column.id} value={column.id}>
                {column.name}
              </Select.Option>
            ))}
            {columnType === "DateTime" && (
              <Select.Option value={0}>
                {t("translation:current_day")}
              </Select.Option>
            )}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
}
