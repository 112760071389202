import { Col, Divider, Row, Button } from "antd";
import { ArrowRightOutlined, WarningOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import homeIcon from "/app/src/images/home-alt.svg";
import "./_updateLicense.scss";
import { useStatusContext } from "/app/src/contexts/hooks/useStatusContext";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

/**
 *
 * @returns Update License page
 */
export default function UpdateLicense() {
  const { t } = useTranslation();
  const { activeWarehouses } = useStatusContext();
  const navigate = useNavigate();

  const goToLicenseForm = useCallback(() => {
    navigate("/update-license/form");
  }, [navigate]);

  return (
    <div>
      <Helmet>
        <title>{t("translation:update_license")} - ItemPath</title>
      </Helmet>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          lg={{ span: 6, offset: 9 }}
        >
          <div className="box">
            <WarningOutlined className="warningIcon" />
            <span className="header">
              {t("translation:update_your_license")}
            </span>
            <div className="paragraph">
              {t("translation:warehouse_exceeded")}
            </div>
            <Divider />
            <div className="homeIconContainer">
              <img src={homeIcon} alt="Home" className="homeIcon" />
            </div>
            <div className="subHeader">
              {t("translation:active_warehouses")}
            </div>
            <div className="licenseInfo">
              <span className="licenseLimit">{activeWarehouses}</span>
              <span className="licenseReached">
                {t("translation:license_limit_reached")}
              </span>
            </div>
            <Divider />
            <div className="buttonContainer">
              <Button
                size="large"
                className="updateLicenseButton"
                onClick={goToLicenseForm}
              >
                {t("translation:update_your_license")} <ArrowRightOutlined />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
