import { ExclamationCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

/**
 *
 * @param param0 versionChanged is a boolean that is true if the version has changed
 * @returns NewVersionStatus, this is a notification that will appear if the version has changed
 */
export default function NewVersionStatus({
  versionChanged,
}: {
  versionChanged: boolean;
}) {
  const { t } = useTranslation();

  const openNotification = useCallback(() => {
    const key = "version";
    notification.open({
      key,
      message: t("translation:license_version"),
      description: t("translation:license_version_message"),
      style: {
        cursor: "pointer",
      },
      duration: 0,
      icon: <ExclamationCircleOutlined style={{ color: "#faad14" }} />,
      onClick: () => {
        notification.close(key);
        window.location.reload();
      },
    });
  }, [t]);

  if (versionChanged) {
    openNotification();
  }
  return null;
}
